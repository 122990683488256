<template>
  <div>
    <h1>⭐God Jul⭐ </h1>
    <h2>...til dæ 😘💞</h2>
    <div class="cards-wrapper">
      <ul class="cards">
        <li
          class="card"
          :style="fadeIn(i)"
          v-for="i in range(1,25)"
          :key="i"
        >
          <div>
            <router-link 
              :to="'/day/' + i"
              >
              {{ i }}
            </router-link>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  name: 'Calendar',
  data() {
    return {
      disabled: false,
    }
  },
  methods: {
    range: function (min, max) {
      let arr = []
      let j = 0
      for (let i = min; i <= max; i++) {
        arr[j++] = i
      }

      arr[24] = 31

      return arr
    },
    fadeIn(day) {
      const year = moment().year()

      if (year > 2024) {
        return {
          'opacity': '1'
        }
      }

      if (day <= 24)
      {
        return {
          'opacity': '1'
        }
      }

      if (day == 31) {
        const dayNow = process.env.VUE_APP_NODE_ENV === 'development' ?  27 : moment().date();
        // const dayNow = 31;

        if (dayNow <= 23)
        {
          return {
            'opacity': '0'
          }
        }        

        switch (dayNow) {
          case 24:
            return {
              'opacity': '0.05'
            }
          case 25:
            return {
              'opacity': '0.1'
            }
          case 26:
            return {
              'opacity': '0.2'
            }
          case 27:
            return {
              'opacity': '0.3'
            }
          case 28:
            return {
              'opacity': '0.4'
            }
          case 29:
            return {
              'opacity': '0.5'
            }
          case 30:
            return {
              'opacity': '0.6'
            }
          case 31:
            return {
              'opacity': '1'
            }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cards-wrapper {
  width: 1200px;
  height: 600px;
  margin: 0 auto;
  max-width: 100%;
}

.cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @media screen and (max-width: 385px) {
    justify-content: center;
  }
}

.card {
  width: 100px;
  height: 100px;

  border-radius: 25px;

  margin: 35px;

  background: rgba(#000, 0.1);
  border: 1px solid #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.5s;

  &:hover {
    cursor: pointer;
    background: rgba(#000, 0.3);
    box-shadow: 0 0 20px rgba(#ffbb00 , 0.7);
  }

  a {
    outline: 0;
    color: #fff;
    text-decoration: none;

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:active,
    &:focus {
      border: none;
    }
  }

  div {
    font-family: 'Handlee', cursive;
    font-size: 52px;
    font-weight: bold;
    padding-top: 5px;

    width: 100%;
    height: 100%;
  }
}

h1 {
  margin-top: 50px;
  margin-bottom: 20px;
}

h2 {
    font-family: 'Handlee', cursive;
    font-size: 30px;
    margin: 0;
}  

li {
  &:focus-within,
  &:active,
  &:focus {
    will-change: transform;
    animation: shake 1s cubic-bezier(1, 1, 1, 1) both;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@-webkit-keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

</style>
