<template>
  <div>
    <Day day="22.12.2024">
      <div slot="page-content">
        <h3>Luke 22🤶💋🎅</h3>
        <p>
          <img
              src="../../images/22.jpg"
              alt=""
          >
        </p>
        <p>
          Dagens sitat:
        </p>
        <p>
          "Elsk og gled deg over barna dine slik de er, slik de ser ut, det de gjør, og glem de kvalitetene de ikke har."
        </p>
  
      </div>
    </Day>
    <audio v-if="isAllowedToView()" autoplay id="myaudio22">
      <source src="../../assets/22.mp3">
    </audio>    
  </div>
</template>

<script>

import Day from '../Day.vue'
const Utils = require('../../common/utils');

export default {
  name: 'Day22',
  components: {
    Day
  },
  methods: {
    isAllowedToView: function () {
      return Utils.isDayAllowed('22');
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isAllowedToView()) {
      var audio = document.getElementById("myaudio22");
      audio.pause();
      next();
    }
    else {
      next();
    } 
  }
}
</script>
<style scoped>
  h3 {
    font-family: 'Handlee', cursive;
    font-size: 40px;
  }

  p {
    font-family: 'Handlee', cursive;
    font-size: 20px;
  }

  img {
    height: 100%;
    width: 100%; 
    object-fit: contain;
  }  

</style>