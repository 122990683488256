<template>
  <div>
    <Day day="20.12.2024">
      <div slot="page-content">
        <h3>Luke 20👙</h3>
        <p>
          <img
              src="../../images/20.jpg"
              alt=""
          >
        </p>
        <p>
          Dagens sitat:
        </p>
        <p>
          "En leders oppgave er å ta folk fra der de er til der de aldri har vært."
        </p>
      </div>
    </Day>
    <audio v-if="isAllowedToView()" autoplay id="myaudio20">
      <source src="../../assets/20.mp3">
    </audio>    
  </div>
</template>

<script>

import Day from '../Day.vue'
const Utils = require('../../common/utils');

export default {
  name: 'Day20',
  components: {
    Day
  },
  methods: {
    isAllowedToView: function () {
      return Utils.isDayAllowed('20');
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isAllowedToView()) {
      var audio = document.getElementById("myaudio20");
      audio.pause();
    }
    next();
  }
}
</script>
<style scoped>
  h3 {
    font-family: 'Handlee', cursive;
    font-size: 40px;
  }

  p {
    font-family: 'Handlee', cursive;
    font-size: 20px;
  }

  img {
    height: 100%;
    width: 100%; 
    object-fit: contain;
  }  


</style>