<template>
  <Day day="02.12.2024">
    <div slot="page-content">
      <h3>Luke 2🦴</h3>
      <p>
        <img
          src="../../images/2.jpg"
          alt=""
        >
      </p>
      <p>
        Dagens sitat:
      </p>
      <p>
        "Man bør fortelle sannheten, men ikke alle sannheter bør fortelles..."
      </p>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day02',
  components: {
    Day
  }
}
</script>
<style scoped>
  h3 {
    font-family: 'Handlee', cursive;
    font-size: 40px;
  }

  p {
    font-family: 'Handlee', cursive;
    font-size: 20px;
  }

  img {
    height: 100%;
    width: 100%; 
    object-fit: contain;
  }  
</style>
