<template>
  <div>
    <Day day="15.12.2024">
      <div slot="page-content">
        <h3>Luke 15🛵</h3>
        <p>
          <img
            src="../../images/15.jpg"
            alt=""
          >
        </p>
        <p>
          Dagens sitat:
        </p>
        <p>
          "Ingen kan gå tilbake og lage en ny begynnelse, men alle kan begynne i dag og lage en ny slutt."
        </p>
      </div>
    </Day>
    <audio v-if="isAllowedToView()" autoplay id="myaudio15">
      <source src="../../assets/15.mp3">
    </audio>    
  </div>
</template>

<script>

import Day from '../Day.vue';
const Utils = require('../../common/utils');

export default {
  name: 'Day15',
  components: {
    Day
  },
  methods: {
    isAllowedToView: function () {
      // return true;
      return Utils.isDayAllowed('15');
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isAllowedToView()) {
      var audio = document.getElementById("myaudio15");
      audio.pause();
    }
    next();
  }
}
</script>
<style scoped>
  h3 {
    font-family: 'Handlee', cursive;
    font-size: 40px;
  }

  p {
    font-family: 'Handlee', cursive;
    font-size: 20px;
  }

  img {
    height: 100%;
    width: 100%; 
    object-fit: contain;
  }  

</style>