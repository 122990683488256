<template>
  <div>
    <Day day="06.12.2024">
      <div slot="page-content">
        <h3>Luke 6💃</h3>
        <p>
          <img
            src="../../images/6.jpg"
            alt=""
          >
        </p>
        <p>
          Dagens sitat:
        </p>
        <p>
          "Den eneste måten å bli kvitt en fristelse på er å overgi seg til den."
        </p>
      </div>
    </Day>
    <audio v-if="isAllowedToView()" autoplay id="myaudio6">
      <source src="../../assets/6.mp3">
    </audio>    
  </div>
</template>

<script>

import Day from '../Day.vue'
const Utils = require('../../common/utils');

export default {
  name: 'Day06',
  components: {
    Day
  },
  methods: {
    isAllowedToView: function () {
      // return true;
      return Utils.isDayAllowed('6');
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isAllowedToView()) {
      var audio = document.getElementById("myaudio6");
      audio.pause();
    }
    next();
  }
}
</script>
<style scoped>
  h3 {
    font-family: 'Handlee', cursive;
    font-size: 40px;
  }

  p {
    font-family: 'Handlee', cursive;
    font-size: 20px;
  }

  img {
    height: 100%;
    width: 100%; 
    object-fit: contain;
  }  

</style>