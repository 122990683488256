<template>
  <div>
    <Day day="10.12.2024">
      <div slot="page-content">
        <h3>Luke 10🍫</h3>
        <p>
          <img
            src="../../images/10.jpg"
            alt=""
          >
        </p>
        <p>
          Dagens sitat:
        </p>
        <p>
          "Geniale løsninger krever én prosent inspirasjon og nittini prosent svette."
        </p>
      </div>
    </Day>
    <audio v-if="isAllowedToView()" autoplay id="myaudio10">
      <source src="../../assets/10.mp3">
    </audio>    
  </div>
</template>

<script>

import Day from '../Day.vue'
const Utils = require('../../common/utils');

export default {
  name: 'Day10',
  components: {
    Day
  },
  methods: {
    isAllowedToView: function () {
      return Utils.isDayAllowed('10');
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isAllowedToView()) {
      var audio = document.getElementById("myaudio10");
      audio.pause();
    }
    next();
  }

}
</script>

<style scoped>
  h3 {
    font-family: 'Handlee', cursive;
    font-size: 40px;
  }

  p {
    font-family: 'Handlee', cursive;
    font-size: 20px;
  }

  img {
    height: 100%;
    width: 100%; 
    object-fit: contain;
  }  

</style>