<template>
  <div>
    <Day day="14.12.2024">
      <div slot="page-content">
        <h3>Luke 14👳‍♂️👳‍♂️👳‍♂️</h3>
        <p>
          <img
            src="../../images/14.jpg"
            alt=""
          >
        </p>
        <p>
          Dagens sitat:
        </p>
        <p>
          "Mot er å gjøre noe du er redd for å gjøre. Du kan ikke være modig hvis du ikke er redd."
        </p>
  
      </div>
    </Day>
    <audio v-if="isAllowedToView()" autoplay id="myaudio14">
      <source src="../../assets/14.mp3">
    </audio>    
  </div>
</template>

<script>
import Day from '../Day.vue'
const Utils = require('../../common/utils');

export default {
  name: 'Day14',
  components: {
    Day
  },
  methods: {
    isAllowedToView: function () {
      // return true;
      return Utils.isDayAllowed('14');
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isAllowedToView()) {
      var audio = document.getElementById("myaudio14");
      audio.pause();
    }
    next();
  }

}
</script>
<style scoped>
  h3 {
    font-family: 'Handlee', cursive;
    font-size: 40px;
  }

  p {
    font-family: 'Handlee', cursive;
    font-size: 20px;
  }

  img {
    height: 100%;
    width: 100%; 
    object-fit: contain;
  }  

</style>