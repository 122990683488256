<template>
  <Day day="05.12.2024">
    <div slot="page-content">
      <h3>Luke 5🏠</h3>
      <p>
        <img
          src="../../images/5.jpg"
          alt=""
        >
      </p>
      <p>
        Dagens sitat:
      </p>
      <p>
        "Jeg følger ingen regler, jeg prøver å få noe gjort."
      </p>
    </div>
  </Day>
</template>

<script>
import Day from '../Day.vue'

export default {
  name: 'Day05',
  components: {
    Day
  }
}
</script>
<style scoped>
  h3 {
    font-family: 'Handlee', cursive;
    font-size: 40px;
  }

  p {
    font-family: 'Handlee', cursive;
    font-size: 20px;
  }

  img {
    height: 100%;
    width: 100%; 
    object-fit: contain;
  }  

</style>