<template>
  <div>
    <div class="background">
      <video 
        v-if="isAllowedToView()"
        autoplay 
        loop 
        id="video-background" 
        muted 
        playsinline>
        <source :src="video.src" :type="video.type" />
      </video>
    </div>
    <Day day="31.12.2024">
      <div slot="page-content">
        <div class="title-day">
          <div>
            <h3 class="anim-heart">😘💫</h3>
          </div>
          <div>
            <h3 class="anim-scale-text">Godt Nytt År Fina</h3>
          </div>
          <div>
            <h3 class="anim-heart">🥂🍾</h3>
          </div>
        </div>
        <p>
          <img
            class="glow"
            src="../../images/31.jpg"
            alt=""
          >
        </p>
        <p>
          Dagens sitat:
        </p>
        <p>
          "Til alle dokker som æ har fornærma i løpe av året"
        </p>
        <p>
          "JOBB med dokker sjøl sånn at æ slæpp å gjøre det igjen tel næste år!!!"
        </p>
        <p>
          😎👉😲
        </p>
      </div>
    </Day>
    <audio v-if="isAllowedToView()" autoplay id="myaudio31">
      <source src="../../assets/31.mp3">
    </audio>    
  </div>
</template>

<script>

import Day from '../Day.vue'
const Utils = require('../../common/utils');

export default {
  name: 'Day24',
  methods: {
    isAllowedToView: function () {
      // return true;
      return Utils.isDayAllowed('31');
    },
  },
  computed: {
    video: function () {
      return {
        src: require('../../assets/snowflakes.mp4'),
        type: 'video/mp4'
      }
    }
  },  
  components: {
    Day
  },
  beforeRouteEnter(to, from, next) {
    if (Utils.isDayAllowed('31')) {
      next();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isAllowedToView()) {
      var audio = document.getElementById("myaudio31");
      audio.pause();
    }
    next();
  }
}
</script>

<style scoped>

  #video-background {
    /*  making the video fullscreen  */
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
  }

  h3 {
    font-family: 'Handlee', cursive;
    font-size: 60px;
    color: rgb(240, 172, 172);
  }

  p {
    font-family: 'Handlee', cursive;
    font-size: 20px;
  }

  img {
    height: 100%;
    width: 100%; 
    object-fit: contain;
  }  

  .title-day {
    display: flex;
    justify-content: center;
  }

  .anim-scale-text {
    animation: textscale 2s infinite;    
  }

  .anim-heart {
    display: inline-block;
    animation: rotation 4s infinite alternate;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(22.5deg);
    }
    40% {
      transform: rotate(22.5deg);
    }
    60% {
      transform: rotate(-22.5deg);
    }
    80% {
      transform: rotate(-22.5deg);
    }
    100% {
      transform: rotate(22.5deg);
    }
  }

  @keyframes textscale {
    0% {
      transform: scale(1);
      color: #ff3300;
    }
    30% {
      transform: scale(1.1);
      color: #ff9100;
    }
    60% {
      transform: scale(1.2);
      color: rgb(255, 51, 0);
    }
  }  

  .glow {
    box-shadow: rgba(126, 50, 31, 0.8) 0 0 90px 33px;
  }
</style>